<script setup lang="ts">
import IconCheck from '@/assets/icons/check.svg'

const features = [
  'Lithium ion battery and energy storage supply chains',
  'Renewable energy supply chains',
  'Legacy energy and its relationship with supply chains of the energy transition',
]
</script>

<template>
  <div class="mt-5 flex flex-col gap-y-5">
    <p>
      Your gateway to the supply chains of the energy transition brought to you
      by Benchmark Mineral Intelligence.
    </p>
    <p>
      The <strong>Benchmark Source</strong> newsletter provides insights into
      the key developments across the supply chains of the energy transition.
    </p>
    <p>
      Subscribers receive regular analysis, commentary, data, and videos
      covering the following and more:
    </p>
    <ul>
      <li v-for="feature in features" :key="feature" class="mt-1 flex gap-2">
        <IconCheck class="mt-[.3rem] shrink-0 text-green" />
        <p class="leading-6">{{ feature }}</p>
      </li>
    </ul>
  </div>
</template>
